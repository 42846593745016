// Compiled file located in assets/algolia.js (cf: config/script.js)

const asc = Translator.translate('price_asc');
const desc = Translator.translate('price_desc');
const sessionStorageCache = instantsearch.createInfiniteHitsSessionStorageCache();
const instantSearchRouter =  instantsearch.routers.history();
const { highlight } = instantsearch;
const { rangeSlider } = instantsearch.widgets;
const { searchBox } = instantsearch.widgets;

const client = algoliasearch(`${appId}`, `${token}`);
const index_name = `${index_name_algolia}`;
const price_asc_index_name = `${algolia_price_asc_index_name}`;
const price_desc_index_name = `${algolia_price_desc_index_name}`;
const algolia_suggestions_index_name = `${suggestions_index_name}`;


const index = client.initIndex(index_name);
const { sortBy } = instantsearch.widgets;
const { connectSortBy } = instantsearch.connectors;
const { stats }= instantsearch.widgets;

const searchClient = {
    ...client,
    search(requests) {
        if (requests.every(({ params }) => !params.query || (params.query && params.query.length < 3))) {
            return Promise.resolve({
                results: requests.map(() => ({
                    hits: [],
                    nbHits: 0,
                    nbPages: 0,
                    page: 0,
                    processingTimeMS: 0,
                    hitsPerPage: 0,
                    exhaustiveNbHits: false,
                    query: '',
                    params: '',
                })),
            });
        }
        return client.search(requests);
    },
};

const instantSearchClient = {
    ...client,
    search(requests) {
        if (requests.every(({ params }) => !params.query || (params.query && params.query.length < 3))) {
            return Promise.resolve({
                results: requests.map(() => ({
                    hits: [],
                    nbHits: 0,
                    nbPages: 0,
                    page: 0,
                    processingTimeMS: 0,
                    hitsPerPage: 0,
                    exhaustiveNbHits: false,
                    query: '',
                    params: '',
                })),
            });
        }
        return client.search(requests);
    },
};

const { createQuerySuggestionsPlugin } = window[
    '@algolia/autocomplete-plugin-query-suggestions'
];
const { autocomplete } = window[
    '@algolia/autocomplete-js'
];

let isFromScroll = false;
let scrollPosition = false;
let original_search_term = '';
const container = document.querySelector('#results-wrapper');
const alg_app_container = document.querySelector('.ais-InstantSearch');

const search = instantsearch({
    indexName: index_name,
    searchClient: instantSearchClient,
    minLength: 3,
    searchFunction(helper) {

        const container = document.querySelector('#results-wrapper');

        const alg_app_container = document.querySelector('.ais-InstantSearch');

        // Display or hide container depending on query

        if (helper.state.query.length < 3) {
            container.style.display = 'none';
            document.querySelector('.algolia_footer').style.display = 'none';
            alg_app_container.classList.add('query_loading');
            $("body").addClass("noscroll");

            if (helper.state.query.length == 0) {
                alg_app_container.classList.remove('query_loading');
                $("body").removeClass("noscroll");
                document.querySelector('body').classList.remove('algolia_on');
            }
        } else {

            // Add displayed class on alg_app_container on render method (approx, l223)
            document.querySelector('.algolia_footer').style.display = '';

            if (alg_app_container.classList.contains('query_loading')) {
                alg_app_container.classList.remove('query_loading');
                $("body").removeClass("noscroll");
            }

        }

        /* For version mobile */
        if ( helper.state.query.length > 0 || helper.state.query.length < 3 ) {
            if ( $('body').hasClass('menuLeftOpen') ) {
                $('#left_menu .btn_close').addClass('hide');
            }
        }

        /* For version mobile */
        if ( helper.state.query.length === 0 ) {
            if ( $('body').hasClass('menuLeftOpen') ) {
                $('#left_menu .btn_close').removeClass('hide');
            }
        }

        if (original_search_term !== helper.state.query) {
            helper.clearRefinements().search();
            original_search_term = helper.state.query;
        }


        /* If the query states that there's no result, hide left panel **********/
        if(helper.state.query === 'noResultTrigger'){
            $('.left-panel').hide();
            $('.ais-InstantSearch').addClass('mobile-display');

            $('#no_res_push_bloc_list,#no_res_best_sales_list').show();
        } else {
            // Hide gondole title
            $('.no_results_wrapper, .gondoleTitle').hide();
            $('.ais-InstantSearch').removeClass('mobile-display')
        }

        var query = search.helper.state.query;
        if (query.length > 2) {
            helper.search();
        }

        if (query.length > 0) {
            if(!$("body").hasClass("menuLeftOpen")) {
                $(".algoliaSearchBar").addClass("changeBgColor");
                $(".banner_nav").addClass("changeBgColor");

            } else {
                $(".algoliaSearchBar").removeClass("changeBgColor");
                $(".banner_nav").removeClass("changeBgColor");
                $('#left_menu .btn_search').removeClass('show');
                $('#left_menu .btn_search').addClass('hide');
            }

            setTimeout(() => {
                $(".algoliaSearchBar .aa-InputWrapperSuffix:first-of-type").addClass("greaterThanOne");
            }, 100);

        }

        if (query.length < 1) {
            setTimeout(() => {
                $(".aa-InputWrapperSuffix:first-of-type").removeClass("greaterThanOne");
                $('#left_menu .btn_search').removeClass('hide');

                if (!$('body').hasClass('menu_lv1_open')) {
                    $('#left_menu .btn_search').addClass('show');
                }
            }, 100);
        }

        // Remove loader from loadMore btn when search is processed --> Use timeout to give delay
        setTimeout(function() {
            if ($('.ais-InfiniteHits-loadMore').hasClass('loading')) {
                $('.ais-InfiniteHits-loadMore').removeClass('loading')
            }
        },500);
    },
    routing: {
        stateMapping: {
            stateToRoute(uiState) {
                const indexUiState = uiState[index_name] || {};
                return {
                    query: indexUiState.query,
                    page: indexUiState.page,
                }
            },
            routeToState(routeState) {
                return {
                    [index_name] : {
                        query: routeState.query,
                        page: routeState.page,
                    },
                };
            },
        },
        router: instantsearch.routers.history({
            parseURL({ qsModule, location }) {
                const { query = '', page, brands = [] } = qsModule.parse(
                    location.search.slice(1)
                );
                // `qs` does not return an array when there's a single value.
                const allBrands = Array.isArray(brands)
                    ? brands
                    : [brands].filter(Boolean);

                if (isStringCorrupted(query)) {
                    return;
                } else {
                    return {
                        query: decodeURIComponent(query),
                        page,
                        brands: allBrands.map(decodeURIComponent),
                        // category
                    };
                }
            },
            createURL({ qsModule, routeState, location }) {

                const urlObj = new URL(location);
                const params = new URLSearchParams(location.search.slice(1));

                // current search params
                const indexState = routeState[index_name] || {};
                const { origin, pathname, hash, search } = location;
                // grab current query string and convert to object
                const queryParameters = qsModule.parse(search.slice(1)) || {};

                // if there is an active search
                if (Object.keys(indexState).length) {
                    // merge the search params with the current query params
                    Object.assign(queryParameters, routeState);
                } else {
                    // remove the search params
                    params.delete('query');
                }

                // Overriding the query parameter with the one from the route state
                if (routeState.query) {
                    if (!isStringCorrupted(routeState.query)) {
                        params.set('query', encodeURIComponent(routeState.query));
                    } else {
                        return;
                    }
                } else {
                    if (params.has('page')) {
                        params.delete('page');
                    }
                }

                if (routeState.page !== undefined && routeState.page !== 1) {
                    params.set('page', routeState.page);
                }

                if (routeState.brands) {
                    params.set('brands', routeState.brands.map(encodeURIComponent));
                }

                // Convert URLSearchParams to a regular JavaScript object
                let queryStringParameters = {};
                params.forEach((value, key) => {
                    queryStringParameters[key] = value;
                });

                // Ensure querystring ordered : query, page ...
                queryStringParameters = preferredOrder(queryStringParameters, ['query', 'page']);

                const queryString = qsModule.stringify(queryStringParameters, {
                    addQueryPrefix: true,
                    arrayFormat: 'repeat'
                });

                // Build the new URL with the filtered parameters
                const newUrl = `${urlObj.origin}${urlObj.pathname}${queryString}`;

                return newUrl;
              },
        })
    },
});

const platform = algolia_mobile ? 'mobile' : 'desktop';

var tgSwiper;
let lastRenderArgs;



const algoliaTarget = algolia_mobile ? ".ais-InstantSearch" : "html, body"

search.on('render', () => {
    // Do something on render

    if ($('.searchItemSlider').length) {
        initItemSlider(false);
    }

    // Add class displayed and display results-wrapper if something more than 3 caracs are typed
    if (!document.querySelector('.ais-InstantSearch').classList.contains('displayed') && (document.querySelector('.ais-SearchBox-input').value.trim().length >= 3)) {
        setTimeout(function () {
            document.querySelector('body').classList.add('algolia_on');
            // not from algolia result product page
            if (!scrollPosition) {
                setBackToTop();
            }
            document.querySelector('.ais-InstantSearch').classList.add('displayed'); // displayed on algolia container first
            document.querySelector('body').classList.add('algolia_displayed'); // algolia_displayed on body after so we dont see any blink
            document.querySelector('#results-wrapper').style.display = '';
        }, 200)
    } else {
        // Checking if there's a NEW search term on render
        if ((search.helper.lastResults != null) && (search.helper.lastResults.query != search.helper.state.query)) {
            // not from algolia result product page
            if (!scrollPosition) {
                setBackToTop();
            }
        }
    }

    // On desktop we're using lazyload.js --> lazyload-new.js from _app repository, use lazyloadImage function when rendering new content
    // Trigger lazyload function when rendering new products on page
    if (!algolia_mobile) {
        lazyloadImage()
    }

});

const infiniteHits = instantsearch.connectors.connectInfiniteHits(
    (renderArgs, isFirstRender) => {
        const { hits, showMore, widgetParams } = renderArgs;
        const { container } = widgetParams;

        lastRenderArgs = renderArgs;

        if (isFirstRender) {
            const sentinel = document.createElement('div');
            container.appendChild(document.createElement('ul'));
            container.appendChild(sentinel);

            const observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting && !lastRenderArgs.isLastPage) {
                        showMore();
                    }
                });
            });

            observer.observe(sentinel);

            return;
        }
    }
);

search.use(
    instantsearch.middlewares.createInsightsMiddleware({
        insightsClient: aa,
    })
);

const virtualRefinementList = instantsearch.connectors.connectRefinementList(
    () => null
);
const virtualSearchBox = instantsearch.connectors.connectSearchBox(() => {});


/* Custom "trier par" element --> HESPERIDE is used only in mobile ******************/
const renderSortBy = (renderOptions, isFirstRender) => {
    const {
        options,
        currentRefinement,
        refine,
        widgetParams,
        // `canRefine` is only available from v4.45.0
        // Use `hasNoResults` in earlier minor versions.
        canRefine,
    } = renderOptions;

    let filter_sortby = '';
    // Clean filter_trier if it exists
    if (!document.querySelector('.ais-InstantSearch #filter_trier')) {
        // Create filter_trier div
        filter_sortby = document.createElement('div');
        filter_sortby.id = 'filter_trier';

        document.querySelector(widgetParams.container).appendChild(filter_sortby)
    }

    // If renderOptions has a matching refinement (asc or desc), set it as the currentRefinementLabel in order to use it in the render (sortby.innerHTML)
    let currentRefinementLabel = '';
    if (!isFirstRender) {

        // If currentRefinement is either asc or desc, set currentRefinementLabel to the matching refinement (to use in the Translator.translate() function)
        if (renderOptions.currentRefinement === price_asc_index_name) {
            currentRefinementLabel = 'croissant';
            $('[name="choice_tri"][value="'+ price_asc_index_name + '"]').addClass('checked');
        } else if (renderOptions.currentRefinement === price_desc_index_name) {
            currentRefinementLabel = 'decroissant';

            $('[name="choice_tri"][value="'+ price_desc_index_name + '"]').addClass('checked');
        }
    }



    // Set sortby as the filter_trier div in the widget container
    const sortby =  document.querySelector(widgetParams.container).querySelector('.ais-InstantSearch #filter_trier');
    let sortby_html;

    if (algolia_mobile) {
        sortby_html = `
            ${options
            .map(
                option => `
                <li class="ais-RefinementList-item">
                    <div class="form_itm check ${(renderOptions.currentRefinement == option.value) ? 'checked' : ''}">
                        <label class="filter_label" data-label="${option.label}">${option.label}</label>
                        <input type="hidden" name="choice_tri" value="${option.value}">
                    </div>
                </li>
                `
            )
            .join('')}
        `;
    } else {
        sortby_html = `
            ${Translator.translate('trier_par')} : <span id="selectedSortFilter">${(currentRefinementLabel !== '') ? Translator.translate('texte_prix_' + currentRefinementLabel) : Translator.translate('pertinence')}</span><div id="pic"></div>
            <ul class="selected_options">
                <li class="form_itm check">
                    <span class="filter_option" data-label="${Translator.translate('pertinence')}">${Translator.translate('pertinence')}</span>
                    <input type="hidden" name="choice_tri" value="${index_name}">
                </li>
            ${options
            .map(
                option => `
                <li class="form_itm check">
                    <span class="filter_option" data-label="${option.label}">${option.label}</span>
                    <input type="hidden" name="choice_tri" value="${option.value}">
                </li>
                `
            )
            .join('')}
            </ul>
        `;
    }

    sortby.innerHTML = sortby_html;


    /* On trier_par itm click, set the selectedSortFilter to the clicked option and refine the search with the selected option */
    $('#sort-by .form_itm').on('click', function() {
        if (!algolia_mobile) {
            $(this).closest('#filter_trier').find('#selectedSortFilter').html(`${$(this).find('.filter_option').attr('data-label')}`);
            // Refine with correct index name
            refine($(this).find('[name="choice_tri"]').val());
        } else {
            /* If clicked element is already checked, remove check and refine blank */
            if ($(this).hasClass('checked')) {
                $(this).removeClass('checked');
                // Reset refinement
                refine(index_name);
            } else {
                /* If clicked element is not checked, remove check on other elements and refine with correct value */
                if ($('#sort-by .form_itm.checked').length) {
                    $('#sort-by .form_itm.checked').removeClass('checked');
                    // Reset refinement
                    refine(index_name);
                }
                // Refine with correct index name
                refine($(this).find('[name="choice_tri"]').val());
            }
        }
    });

    // Add "sort by" reset logic when clearing filters
    $('#clear-refinements_sticky').on('click', function() {
        if ($('#sort-by .form_itm.checked').length) {
            $('#sort-by .form_itm.checked').removeClass('checked');
            // Reset refinement
            refine(index_name);
        }
    });
};
const customSortBy = connectSortBy(
    renderSortBy
);


search.addWidgets([
    virtualSearchBox({}),

    (algolia_mobile ?
    customSortBy({
        container: '#sort-by',
        items: [
            { value: price_asc_index_name, label: asc },
            { value: price_desc_index_name, label: desc },
        ],
    })
    :
    sortBy({
        container: '#sort-by',
        items: [
            { label: Translator.translate('pertinence'), value: index_name },
            { label: Translator.translate('lower_price_first'), value: price_asc_index_name },
            { label: Translator.translate('higher_price_first'), value: price_desc_index_name },
        ],
    })),

    // ROLLOVER FILTER BLOC ************************************************************************************************************ /
    instantsearch.widgets.dynamicWidgets({
        facets: ['*'],
        container: '.ais-InstantSearch #filter_sticky_search .filters_container_wrapper',
        // Fallback Widget for every other facets to display that are not independently declared
        fallbackWidget: ({ container, attribute }) =>
            instantsearch.widgets.panel({
                container,
                cssClasses: {
                    root: 'filters_dropdown_bloc',
                    header: 'toggleFilter filter_name',
                    body: 'selected_options'
                },
                templates: {
                    header: `<span id="${attrNicename(attribute)}" class="trigger_dropdown" onclick="toggleDropdown(event)">${transformAttribute(attribute)}</span>`,
                },
                hidden(options) {
                    return options.items.length === 0;
                }
            })(instantsearch.widgets.refinementList)({
                container,
                attribute: attribute,
                templates: {
                    item: `
                    <a href="{{url}}" class="form_itm {{#isRefined}}checked{{/isRefined}}" style="{{#isRefined}}font-weight: bold{{/isRefined}}">
                        <label class="filter_label"><span>{{label}}</span> <span class="available_count">{{count}}</span></label>
                    </a>
                    `,
                }
            }
        ),
            widgets: [
                container =>
                instantsearch.widgets.panel({
                    container,
                    cssClasses: {
                        root: 'filters_dropdown_bloc',
                        header: 'toggleFilter filter_name',
                        body: 'selected_options colorOptions'
                    },
                    templates: {
                        header: `<span id="${attrNicename(Translator.translate('algolia_nicename_color'))}" class="trigger_dropdown" onclick="toggleDropdown(event)">${Translator.translate('algolia_nicename_color')}</span>`

                    },
                })(instantsearch.widgets.refinementList)({
                    container,
                    attribute: 'color_name_hex',
                    transformItems: function (items) {
                        return items.map(function(item) {
                            item.color_name = item.label.split("||")[0].toLowerCase();
                            item.color_hex = item.label.split("||")[1].toLowerCase();
                            return item;
                        });
                    },
                    templates: {
                        item: `
                            <a href="{{url}}" class="form_itm color {{#isRefined}}checked{{/isRefined}}" data-color="{{color_name}}"  ${algolia_mobile ? `` : `style="background-color: {{color_hex}}"`}>
                                <label class="color_picto filter_label"><span class="color_name">{{color_name}}</span> <span class="available_count">{{count}}</span>${algolia_mobile ? `<span class="color_bullet" style="background-color: {{color_hex}}"></span>` : ``}</label>
                            </a>
                        `,
                    }
                }),
            ],
    }),
    instantsearch.widgets.clearRefinements({
        container: '#clear-refinements_sticky',
        templates: {
            resetLabel: algolia_mobile ? Translator.translate('algolia_mobile_reset_filters') : Translator.translate('reset_sticky_filters'),
        },
    }),
    // ********************************************************************************************************************************* /


    // TOP LINE FILTER BLOC ************************************************************************************************************ /
    instantsearch.widgets.dynamicWidgets({
        facets: ['*'],
        container: '#holder_filters_sections #trigger_filtre',
        // Fallback Widget for every other facets to display that are not independently declared
        fallbackWidget: ({ container, attribute }) =>
            instantsearch.widgets.panel({
                container,
                cssClasses: {
                    root: 'filters_dropdown_bloc',
                    header: 'toggleFilter',
                    body: 'selected_options cache'
                },
                templates: {
                    header(options, {html}) {
                        if (options.results) {
                            return `<span id="${attrNicename(options.widgetParams.attribute)}" class="filtre_elem ${(options.state.disjunctiveFacetsRefinements[options.widgetParams.attribute] && options.state.disjunctiveFacetsRefinements[options.widgetParams.attribute].length > 0) ? 'toggled' : ''}" onclick="displayFilters('${attrNicename(options.widgetParams.attribute)}')">${transformAttribute(options.widgetParams.attribute)} ${(options.state.disjunctiveFacetsRefinements[options.widgetParams.attribute] && options.state.disjunctiveFacetsRefinements[options.widgetParams.attribute].length > 0) ? ('<span class="count_filters">(' + options.state.disjunctiveFacetsRefinements[options.widgetParams.attribute].length + ')</span></span>') : ''}`;
                        }
                    }
                },
                hidden(options) {
                    return options.items.length === 0;
                }
            })(instantsearch.widgets.refinementList)({
                container,
                attribute: attribute,
                templates: {
                    item: ``,
                }
            }
        ),
            widgets: [
                container =>
                instantsearch.widgets.panel({
                    container,
                    cssClasses: {
                        root: 'filters_dropdown_bloc',
                        header: 'toggleFilter',
                        body: 'selected_options colorOptions cache'
                    },
                    templates: {
                        header(options, {html}) {
                            return `<span id="${attrNicename(Translator.translate('algolia_nicename_color'))}" class="filtre_elem ${(options.state.disjunctiveFacetsRefinements[options.widgetParams.attribute] && options.state.disjunctiveFacetsRefinements[options.widgetParams.attribute].length > 0) ? 'toggled' : ''}" onclick="displayFilters('${attrNicename(Translator.translate('algolia_nicename_color'))}')">${Translator.translate('algolia_nicename_color')} ${(options.state.disjunctiveFacetsRefinements[options.widgetParams.attribute] && options.state.disjunctiveFacetsRefinements[options.widgetParams.attribute].length > 0) ? ('<span class="count_filters">(' + options.state.disjunctiveFacetsRefinements[options.widgetParams.attribute].length + ')</span></span>') : ''}`;
                        }
                    },
                })(instantsearch.widgets.refinementList)({
                    container,
                    attribute: 'color_name_hex',
                    templates: {
                        item: ``,
                    }
                }),
            ],
    }),

    instantsearch.widgets
    .index({ indexName: algolia_suggestions_index_name  })
    .addWidgets([
        instantsearch.widgets.hits({
            container: document.querySelector('#query_suggestions_list'),
            cssClasses: {
                root: 'swiper-container',
                list: 'list_suggestions swiper-wrapper',
                item: `swiper-slide suggestion`,
            },
            transformItems( items, { results }) {
                return items.map(item => ({
                    ...item,
                    query: results.query,
                }));
            },
            templates: {
                item(hit) {

                    const query = hit.objectID;
                    return `<a
                    href='javascript:void(0);'
                    onClick="initAutcompleteFromSuggestions('${hit.objectID}');return false;"
                    class="res-suggestion-item aa-ItemLink ${(original_search_term) ? (query === original_search_term.toLowerCase() ? 'selected' : '') : ''}">${hit.objectID}
                    </a>`;
                },
                empty: ''
            },
        }),
    ]),
    instantsearch.widgets.configure({
        clickAnalytics: true,
        hitsPerPage: 60,
        ruleContexts: [platform],
    }),
    instantsearch.widgets.infiniteHits({
        container: '#hits',
        transformItems : function (items) {
            return items.map(function(item) {

                item.price_unit = getPartPrice(item.price, 'units')
                item.price_cent = getPartPrice(item.price, 'cents')
                item.no_cents_class = (item.price_cent == '00') ? 'no_cents' : '';

                item.discounted_price_unit = getPartPrice(item.discounted_price, 'units')
                item.discounted_price_cent = getPartPrice(item.discounted_price, 'cents')
                item.no_cents_class_discounted = (item.discounted_price_cent == '00') ? 'no_cents' : '';

                item.price_per_produit_unit = getPartPrice(item.price_per_produit, 'units');
                item.price_per_produit_cent = getPartPrice(item.price_per_produit, 'cents');
                item.no_cents_class_per_produit = (item.price_per_produit_cent == '00') ? 'no_cents' : '';

                item.discounted_price_per_produit_unit = getPartPrice(item.discounted_price_per_produit, 'units');
                item.discounted_price_per_produit_cent = getPartPrice(item.discounted_price_per_produit, 'cents');
                item.no_cents_class_discounted_per_produit = (item.discounted_price_per_produit_cent == '00') ? 'no_cents' : '';

                return item;
            });
        },
        templates: {
            item(hit, { html, components, sendEvent }) {
                return itemTemplate(hit, hit.rate, false);
            },
            showPreviousText: Translator.translate('algolia_showless'),
            showMoreText: Translator.translate('algolia_showmore'),

            empty(results, { html }) {
                // summary: on empty results, do not trigger noresults unless query is greater than or equal to 3 characters.
                var primary_results = results.query;
                if (primary_results.trim().length >= 3 && results.nbHits == 0 && !primary_results.endsWith(" ")) {
                    // set query to rules
                    no_res_push_search.helper.setQuery('noResultTrigger');
                    // attach noreults hits to custom design no_res_push_search
                    no_res_push_search.addWidget(no_res_push_searchHits);
                    document.getElementById("algolia_visited_articles").style.cssText = "display: block;";
                    document.getElementById("noresult").style.cssText = "display: flex;";
                }


                let params_swiper,
                params_categ_swiper,
                params_visited_swiper;
                if (algolia_mobile) {
                    params_swiper = {
                        roundLengths: true,
                        setWrapperSize: true,
                        slidesPerView: 2,
                        spaceBetween: 10,
                        nextButton: '.container_inject_gondole .no_res_next',
                        prevButton: '.container_inject_gondole .no_res_prev',
                        scrollbar: ".container_inject_gondole .no_res_scrollbar",
                        draggable: true,
                        scrollbarDraggable: true,
                        scrollbarHide: false,
                    };
                    params_categ_swiper = {
                        roundLengths: true,
                        setWrapperSize: true,
                        slidesPerView: 2.1,
                        spaceBetween: 12,
                    };
                    params_visited_swiper = {
                        slidesPerView: 2,
                        paginationClickable: true,
                        loop: false,
                        preloadImages: true,
                        lazyLoading: true,
                        spaceBetween: 12,
                        nextButton: '#algolia_visited_articles #gondole_derniers_articles_vus .visited-next.swiper-button-next',
                        prevButton: '#algolia_visited_articles #gondole_derniers_articles_vus .visited-prev.swiper-button-prev',
                        scrollbar: '#algolia_visited_articles #gondole_derniers_articles_vus .swiper-scrollbar',
                        scrollbarDraggable: true,
                        scrollbarHide: false,
                    }
                } else {
                    params_swiper = params_visited_swiper = {
                        slidesPerView: 'auto',
                        spaceBetween: 29,
                        roundLengths: true,
                        setWrapperSize: true,
                        breakpoints: {
                            1300: {
                                slidesPerView: 5,
                            },
                        }
                    }

                    params_swiper.navigation = {
                        nextEl: '.container_inject_gondole .no_res_next',
                        prevEl: '.container_inject_gondole .no_res_prev',
                    }

                    params_visited_swiper.navigation = {
                        nextEl: '#algolia_visited_articles .next_prod_visited_slider',
                        prevEl: '#algolia_visited_articles .prev_prod_visited_slider',
                    }
                }

                // Initialize gondole from Algolia BO parsed in DOM
                setTimeout(function() {
                    initializeSwiper('.inject_gondole .swiper-container', params_swiper);
                    if (algolia_mobile) {
                        initializeSwiper('.algolia_categ_slider', params_categ_swiper);

                        // Visited articles custom initialisation
                        if (!$('#algolia_visited_articles .swiper-scrollbar').length) {
                            var scrollBar = $( "<div>", { "class": "swiper-scrollbar" } );
                            $( '#algolia_visited_articles .visited-prev, #algolia_visited_articles .visited-next, #algolia_visited_articles .visited-pagination' ).wrapAll( $( '<div>' ).addClass('wrap_navigation') );
                            $( '#algolia_visited_articles .visited-pagination' ).addClass('swiper-pagination');
                            scrollBar.prependTo( $( '#algolia_visited_articles #gondole_derniers_articles_vus .wrap_navigation' ) );
                        }

                        if ( $( '#algolia_visited_articles #productVisitedSwiper .swiper-slide' ).length > 2 !== true) {
                            $( '#algolia_visited_articles .wrap_navigation' ).addClass('hide');
                        }

                        initializeSwiper('#algolia_visited_articles #productVisitedSwiper', params_visited_swiper);
                    } else {
                        initializeSwiper('#algolia_visited_articles #productVisitedSwiper', params_visited_swiper);
                    }
                }, 400)
                $('.left-panel').hide();

                return '';
            },
        },
        cache: sessionStorageCache,
    }),
    instantsearch.widgets.analytics({
        pushFunction() {
            // push index Name / userToken to DataLayer
            window.dataLayer = window.dataLayer || []

            if (index_name) {
                window.dataLayer.push({
                    algoliaIndexName: index_name
                })
            }

            // push unique UserToken to dayalayer

            function getCookie(name) {
                var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
                return v ? v[2] : null;
            }

            // if user connected
            let connectedUserToken = '';
            if(dataLayer !== undefined && dataLayer !== null && dataLayer[0] !== undefined && dataLayer[0] !==null && dataLayer[0].user !== undefined && dataLayer[0].user !== null) {
                connectedUserToken = dataLayer[0].user.md5;
            }

            let token = getCookie('_ALGOLIA');
            if (connectedUserToken) {
                window.dataLayer.push({
                    algoliaUserToken: connectedUserToken
                })
            } else if (connectedUserToken === '' && token !== '' && token !== undefined) {
                window.dataLayer.push({
                    algoliaUserToken: token
                })
            }
        },
        pushInitialSearch: false,
        pushPagination: true
    }),
    instantsearch.widgets.queryRuleCustomData({
        container: '#banner_algolia_search',
        templates: {
            default: `
                {{#items}}
                    <a href="{{banner_redirect}}"><img id="banner_algolia_search_img" src={{banner_image}} alt={{banner_text}}/></a>
                {{/items}}
            `
        },
    }),
    stats({
        container: '#stats',
        templates: {
            text(data, { html }) {
                let count = '';

                // Handle suggestions swiper
                setTimeout(function() {
                    let params_swiper;
                    if (algolia_mobile) {
                        params_swiper = {
                            init: $('#query_suggestions_list .swiper-slide').length > 2 ? true : false,
                            roundLengths: true,
                            setWrapperSize: true,
                            slidesPerView: 'auto',
                            spaceBetween: 10,
                        }
                    } else {
                        params_swiper = {
                            init: $('#query_suggestions_list .swiper-slide').length > 5 ? true : false,
                            roundLengths: true,
                            setWrapperSize: true,
                            slidesPerView: 'auto',
                            spaceBetween: 10,
                            navigation: {
                                prevEl: '.suggestions_prev',
                                nextEl: '.suggestions_next',
                            },
                            observer: true
                        }
                    }
                    initializeSwiper('#query_suggestions_list .swiper-container', params_swiper);
                }, 500);

                // HIDE AND DISPLAY CORRECT CONTENT
                if (data.query && data.query.length > 0) {
                    if (data.hasManyResults) {
                        count += `${data.nbHits + ' ' + Translator.translate('algolia_results_products')}`;
                        document.getElementById("noresult").style.cssText = "display: none;";
                        document.getElementById("algolia_visited_articles").style.cssText = "display: none;";

                        if (document.querySelector("#stats").classList.contains('no_results')) {
                            document.querySelector("#stats").classList.remove('no_results');
                        }

                        $('.left-panel').show();
                    } else if (data.hasOneResult) {
                        document.getElementById("noresult").style.cssText = "display: none;";
                        document.getElementById("algolia_visited_articles").style.cssText = "display: none;";


                        if (document.querySelector("#stats").classList.contains('no_results')) {
                            document.querySelector("#stats").classList.remove('no_results');
                        }

                        count += `1 ${Translator.translate('algolia_results_product')}`;    // translates below comment
                    } else {
                        count += `${data.nbHits + ' ' + Translator.translate('algolia_results_product')}`;

                        document.querySelector("#stats").classList.add('no_results');

                        let final_txt = '<div class="no_result_txt">' + Translator.translate((algolia_mobile ? 'no_response_search_mobile' : 'no_response_search')) + '</div>'
                        return final_txt;
                    }

                    return html`<span class="result_query">${data.query}</span> <span class="result_count">${count}</span>`; // constant & style to be added
                }

            },
        },
    }),
    stats({ // Add also stats next to sortBy filter
        container: '.filter_nb_results',
        templates: {
            text(data, { html }) {
                let count = '';

                if (data.hasManyResults) {
                    count += `${data.nbHits + ' ' + Translator.translate('products')}`;
                } else if (data.hasOneResult) {
                    count += `1 ${Translator.translate('product')}`;    // translates below comment
                } else {  count += ` Aucun résultat ne correspond à votre recherche
                    Pensez à vérifier l’orthographe des mots saisis, effacer vos filtres actifs ou taper directement
                    la référence du
                    produit`;
                    return html`<div><p> <strong>"${data.query}"</strong></p><p>(0 Produit)</p></div><div><p><span>${count}</span></p></div>`
                }

                return html`<span class="result_count">${count}</span>`; // constant & style to be added
            },
        },
    })
]);



search.addWidget(
    instantsearch.widgets.rangeSlider({
        container: '#range-slider',
        attribute: 'price',
        pips: false,
        tooltips: {
            format: function(rawValue) {
                return `${rawValue + decode(currencySymbol)}` ;
            }
        },
        precision: 2
    })
);
// no results page bloc pi
const no_res_push_search = instantsearch({
    searchClient: searchClient,
    indexName: index_name,
        // force and trigger only on no results rule query noResultTrigger
         searchFunction(helper) {
            if (helper.state.query === 'noResultTrigger') {
              helper.search();
            }
          },
});

const no_res_push_searchHits = instantsearch.widgets.hits({
    container: document.querySelector('.inject_gondole'),
    hitsPerPage: 6,
    cssClasses: {
        root: 'swiper-container',
        list: 'swiper-wrapper',
        item: 'swiper-slide'
    },
    transformItems : function (items) {
        // Handle case when there are no items set in Algolia BO gondole
        if (items.length == 0) {
            document.querySelector('.noresult_gondole_title').classList.add('no_swiper');
            document.querySelector('.inject_gondole').classList.add('no_swiper');
            container.classList.add('no_swiper');
        } else {
            if (document.querySelector('.noresult_gondole_title').classList.contains('no_swiper')) {
                document.querySelector('.noresult_gondole_title').classList.remove('no_swiper');
                container.classList.remove('no_swiper');
            }
        }

        return items.map(function(item) {

            item.price_unit = getPartPrice(item.price, 'units')
            item.price_cent = getPartPrice(item.price, 'cents')
            item.no_cents_class = (item.price_cent == '00') ? 'no_cents' : '';

            item.discounted_price_unit = getPartPrice(item.discounted_price, 'units')
            item.discounted_price_cent = getPartPrice(item.discounted_price, 'cents')
            item.no_cents_class_discounted = (item.discounted_price_cent == '00') ? 'no_cents' : '';

            item.price_per_produit_unit = getPartPrice(item.price_per_produit, 'units');
            item.price_per_produit_cent = getPartPrice(item.price_per_produit, 'cents');
            item.no_cents_class_per_produit = (item.price_per_produit_cent == '00') ? 'no_cents' : '';

            item.discounted_price_per_produit_unit = getPartPrice(item.discounted_price_per_produit, 'units');
            item.discounted_price_per_produit_cent = getPartPrice(item.discounted_price_per_produit, 'cents');
            item.no_cents_class_discounted_per_produit = (item.discounted_price_per_produit_cent == '00') ? 'no_cents' : '';

            return item;
        });
    },
    templates: {
        item(hit, { html, components }) {
            return itemTemplate(hit, hit.rate, true);
        },
    },

});

// handle redirects rules set in algolia back office rules and search timeout for characters input
let timerId;
let timeout = 250;
search.addWidgets([
    searchBox({
        container: "#searchbox",
        placeholder: Translator.translate('site_search_placeholder'),
        queryHook(query, refine) {
            if (query.length < 3) {
                $('body').removeClass('algolia_displayed');
                $('.ais-InstantSearch').removeClass('displayed');
            }
            clearTimeout(timerId);
            timerId = setTimeout(() => refine(query), timeout);
        },
    }),
]);
no_res_push_search.start()
search.start();

// Set the InstantSearch index UI state from external events.
function setInstantSearchUiState(indexUiState) {
    search.setUiState(uiState => ({
        ...uiState,
        [index_name]: {
            ...uiState[index_name],
            // We reset the page when the search state changes.
            ...indexUiState,
        },
    }))
}
// Return the InstantSearch index UI state.
function getInstantSearchUiState() {
    const uiState = instantSearchRouter.read();
    return uiState;
}

function backToTopAlgolia() {
    if (algolia_mobile) {
        var scrollTop = $('.ais-InstantSearch').scrollTop(),
        speed = Math.min(scrollTop * 0.8, window.innerHeight);

        $(".ais-InstantSearch").animate({scrollTop: 0}, (speed * 2));
    } else {
        $('html, body').animate({
            scrollTop: 0
        }, 700);
    }
}

// Used to display mobile filters
function toggleAlgoFilters(target = null) {
    var menu = document.getElementById('filter_sticky_search');
    var shad = document.getElementById('algolia_shade');

    if (menu) {

        if (menu.classList.toggle('open') && shad) {
            document.querySelector('.ais-InstantSearch').classList.add('with_filters');
            shad.classList.add('displayed');
            document.body.classList.add("noscroll");
            shad.classList.add('forMenu');

        } else if (shad) {
            document.querySelector('.ais-InstantSearch').classList.remove('with_filters');
            shad.classList.remove('displayed');
            shad.classList.remove('forMenu');
            shad.classList.remove('forFilters');
            document.body.classList.remove("noscroll");
        }
    } else if (shad) {

        shad.classList.toggle('visible');

    }

    if (target !== null && !$(target).parent('.ais-Panel').hasClass('body_visible')) {
        if ($('.ais-Panel.body_visible').length) {
            $('.ais-Panel.body_visible .ais-Panel-body').slideUp(200)
            $('.ais-Panel.body_visible').removeClass('body_visible');
        }
        $(target).parents('.ais-Panel').addClass('body_visible');
        $(target).parents('.ais-Panel').find('.selected_options').slideDown(100);
    }
}

// Build URLs that InstantSearch understands.
function getInstantSearchUrl({query}) {
        return search.createURL({ [index_name]: query }) +`?query=` + encodeURIComponent( `${query}`); // add variable to manage url dynamically
}
const searchPageState = getInstantSearchUiState()

function getItemUrl({ query }) {
    return getInstantSearchUrl({ query });
}
// Detect when an event is modified with a special key to let the browser
// trigger its default behavior.
function isModifierEvent(event) {
    const isMiddleClick = event.button === 1;

    return (
        isMiddleClick ||
        event.altKey ||
        event.ctrlKey ||
        event.metaKey ||
        event.shiftKey
    );
}

function onSelect({ setIsOpen, setQuery, event, query, category }) {
    // You want to trigger the default browser behavior if the event is modified.
    if (isModifierEvent(event)) {
        return;
    }

    setQuery(query);
    setIsOpen(false);
    setInstantSearchUiState({
        query,
    });

    // When rendering after clicking on a suggestion in list
    if ((document.activeElement == document.getElementById('autocomplete-0-input')) && document.getElementById('algolia_shade').classList.contains('displayed')) {
        triggerAlgoInputShad('remove');
    }
}

function createItemWrapperTemplate({ children, query, html, hitsCount }) {

    const uiState = { query };
    let customHit = hitsCount;


    return html`
    <a class="aa-ItemLink" href="${getInstantSearchUrl(uiState)}" onClick="${(event) => {
        if (!isModifierEvent(event)) {
            // Bypass the original link behavior if there's no event modifier
            // to set the InstantSearch UI state without reloading the page.
            event.preventDefault();
        }
    }}">
        ${children}
        ${(customHit !== '') ? (html `<span class="aa-ItemLink_nbSug">${customHit}</span>`) : ''}

    </a>`;
}


const querySuggestionsPlugin = createQuerySuggestionsPlugin({
    searchClient: client,
    indexName: algolia_suggestions_index_name,
    minLength: 3,
    transformSource({ source }) {
        return {
            ...source,
            sourceId: 'querySuggestionsPlugin',
            getItemUrl({ item }) {
                return getItemUrl({
                    query: item.query,
                });
            },
            onSelect({ setIsOpen, setQuery, event, item }) {
                onSelect({
                    setQuery,
                    setIsOpen,
                    event,
                    query: item.query,
                });
            },
            getItems(params) {
                // We don't display Query Suggestions when there's no query.
                if (!params.state.query || params.state.query.length < 3) {
                    return [];
                }


                return source.getItems(params);
            },
            templates: {
                ...source.templates,
                item(params) {

                    const { children } = source.templates.item(params).props;
                    let hitsCount = params.item[index_name]['exact_nb_hits'];  // for production

                    return createItemWrapperTemplate({
                        query: params.item.query,
                        children,
                        html: params.html,
                        hitsCount: hitsCount
                    });

                },
            },
        };
    },
});
let skipInstantSearchUiStateUpdate = false;

// This keeps Autocomplete aware of state changes coming from routing
// and updates its query accordingly
window.addEventListener('popstate', () => {
    skipInstantSearchUiStateUpdate = true;
    setQuery(search.helper?.state.query || '');
});


$(function(){

    if($('.ais-SearchBox-submit').length){
        $('.ais-SearchBox-submit').prop('title', `${Translator.translate('algolia_search')}`);
    };

    // Variable declared on top of the file but set after page load
    original_search_term = $('.aa-Input').val();


    // Handle scroll when Search is active, don't forget to init product swipers on scroll
    var timer;
    let target = !algolia_mobile ? window : '.ais-InstantSearch';

    $(target).on('scroll', function() {

        // Prevent script from triggering too much and causing blank search results
        if (timer) {
            window.clearTimeout(timer);
        }

        // Init prod sliders
        initItemSlider(false);

        timer = window.setTimeout(function() {
            // If button is in viewport, trigger click (next results page load)
            if (isInViewport(document.querySelector('.ais-InfiniteHits-loadMore'))) {
                isFromScroll = true;
                var elementExists = document.querySelector(".ais-InfiniteHits-loadMore");
                if (elementExists) {
                    $(".ais-InfiniteHits-loadMore").addClass('loading');
                    document.querySelector(".ais-InfiniteHits-loadMore").click();
                }
            } else {
                if (isFromScroll) {
                    // If true was set on variable, don't forget to pass it to false once again
                    isFromScroll = false;
                }
            }
        }, 300)
    });


    $(window).on('load', function() {

        const customRegEx = /\?q=/;

        if (customRegEx.test(window.location.href)) {
            displayAlgoResults(alg_app_container, 'load');
        }
    });


    // Specific instructions for Mobile Version
    if (algolia_mobile) {
        $('#algolia_shade').on('click', function(event) {
            toggleAlgoFilters(event);
        });

        // HESPERIDE compactSearch logic inside instantSearch
        var instantSearchElement = document.querySelector('.ais-InstantSearch');
        var statsElement = document.querySelector('.ais-InstantSearch #stats');

        instantSearchElement.addEventListener('scroll', function() {
            var scrollHeight = instantSearchElement.scrollHeight;
            var scrollTop = instantSearchElement.scrollTop;
            var elementHeight = statsElement.offsetHeight;
            var pageBody = document.body;

            if (scrollTop > elementHeight) {
                if (!pageBody.classList.contains('compactSearch')) {
                    pageBody.classList.add('compactSearch');
                }
            } else {
                if (pageBody.classList.contains('compactSearch')) {
                    pageBody.classList.remove('compactSearch');
                }
            }
        });
    }

});

// Execute once
search.once('render', () => {
    scrollPosition = sessionStorage.getItem('scrollPosition');

    if (scrollPosition) { isFromScroll = true;
        setTimeout(() => {
            if (algolia_mobile) {
                $(".ais-InstantSearch").animate({
                    scrollTop: scrollPosition
                }, 2000);

            } else {
                window.scrollTo(0, parseInt(scrollPosition, 10));
            }
            sessionStorage.removeItem('scrollPosition');
        }, 300);
    }
});
function triggerAlgoInputShad(action) {
    const shade = document.getElementById('algolia_shade');
    if (action === 'add') {
        if (document.getElementById('filter_sticky_search').classList.contains('open')) {
            toggleAlgoFilters();
        }
        if (!shade.classList.contains('displayed')) {
            shade.classList.add('displayed');
        }
    } else {
        if (shade.classList.contains('displayed')) {
            shade.classList.remove('displayed');
        }
    }
}

function displayAlgoResults(container, action) {

    if (action == 'load') {
        if (!container.classList.contains('displayed')) {
            container.classList.add('displayed');
        }
    } else if (action == 'add') {
        if ($('.aa-Panel').is(':visible')) {
            $('.aa-Panel').hide();
            if (!container.classList.contains('displayed')) {
                container.classList.add('displayed');
            }
        }
    }

}

/* Handle query when typing in input and clicking a suggestion */
function initAutcompleteFromSuggestions(query) {
    let inputValue = document.querySelector('input#autocomplete-0-input.aa-Input');
    $('input#autocomplete-0-input.aa-Input').val(query)
    let event = document.createEvent('Event');
    event.initEvent('input', true, true);
    inputValue.dispatchEvent(event);

}

/*
*   closeFilters - Used to close rollover filters section
*
**/
function closeFilters() {
    $('.ais-InstantSearch #filter_sticky_search, #algolia_shade').removeClass('displayed');

    if ($('.ais-Panel.body_visible').length) {
        $('.ais-Panel.body_visible .selected_options').slideUp(200);
        $('.ais-Panel.body_visible').removeClass('body_visible');
    }
}

/*
*   toggleDropdown(event) - Used to display matching filters section (depending on target)
*   event => object (event)
**/
function toggleDropdown(event, target = null) {
    let panel = $(event.target).parents('.ais-Panel'),
    panel_body = panel.find('.ais-Panel-body');
    let filter_id = $(event.target).attr('id');

    if (panel_body.length) {
        if (!panel_body.is(':visible')) {

            if ($('.ais-Panel.body_visible').length) {
                $('.ais-Panel.body_visible .ais-Panel-body').slideUp(200)
                $('.ais-Panel.body_visible').removeClass('body_visible');
            }
            panel_body.slideDown(200);
            panel.addClass('body_visible');
        } else {
            panel_body.slideUp(200);
            panel.removeClass('body_visible');
        }
    }
}

/*
*   displayFilters(section) - Used to display rollover filters section
*   section => string
**/
function displayFilters(section) {
    if (section != 'undefined') {

        // Be sure you're not toggling same element back and fourth in order to keep clean display
        if (!$('.ais-InstantSearch #filter_sticky_search').find('#' + section).parents('.ais-Panel').hasClass('body_visible')) {
            $('.ais-InstantSearch #filter_sticky_search').find('#' + section).trigger('click');
        }
        // toggleDropdown
    }
    let shad = $('#algolia_shade');
    if ($('.ais-InstantSearch #filter_sticky_search').hasClass('displayed')) {
        $('.ais-InstantSearch #filter_sticky_search').removeClass('displayed');
        if (shad.hasClass('displayed')) {
            shad.removeClass('displayed');
        }
    } else {
        $('.ais-InstantSearch #filter_sticky_search, #algolia_shade').addClass('displayed');
    }

    shad.on("click touch", function(){
        closeFilters();
    });
}


/*
*   strNoAccent(a) - Function used to remove all accents from attributes name
*   a => string
**/
function strNoAccent(a) {
    var b="áàâäãåçéèêëíïîìñóòôöõúùûüýÁÀÂÄÃÅÇÉÈÊËÍÏÎÌÑÓÒÔÖÕÚÙÛÜÝ",
    c="aaaaaaceeeeiiiinooooouuuuyAAAAAACEEEEIIIINOOOOOUUUUY",
    d="";
    for(var i = 0, j = a.length; i < j; i++) {
        var e = a.substr(i, 1);
        d += (b.indexOf(e) !== -1) ? c.substr(b.indexOf(e), 1) : e;
    }
    return d;
}


/*
*   attrNicename(attribute) - Used to define an id on elements in order to toggle proper dropdown section in rollover filters section
*   attribute => string
**/
function attrNicename(attribute) {
    var str = transformAttribute(attribute);
    // Without accents, without spaces, without '
    str = strNoAccent(str.toLowerCase().replace('\'', '-').replace(/\s+/g, '-'));
    return str;
}


/*
*   transformAttribute(attribute) - Attribute transform in order to display in template
*   attribute => any (must be a string to work properly)
**/

function transformAttribute(attribute) {

    // We check if attr is string and it includes a "." to split on
    if (typeof attribute == 'string') {
        if (attribute.includes(".")) {
            let tmp_attr = attribute.split('.');

            // Check if split has generated an object
            let attr_nicename;
            if ((typeof tmp_attr == 'object') && (tmp_attr.length > 1)) {
                attr_nicename = tmp_attr[1];
            } else {
                attr_nicename = tmp_attr[0];
            }
            // Capitalize
            return attr_nicename.toLowerCase().charAt(0).toUpperCase() + attr_nicename.slice(1)

        } else {

            // if not, return capitalized raw attr
            return attribute.toLowerCase().charAt(0).toUpperCase() + attribute.slice(1);
        }
    } else if (typeof attribute != 'undefined') {
        // if not, return raw attr
        return attribute;
    }
}

/*
*   getPartPrice(price, part) - Used to split raw price in cents and decimal and returning specific part depending on "part"
*   price => number
*   part => string
**/
function getPartPrice(price, part) {
    if (typeof price == 'number') {

        let price_string = price.toString();
        if (price_string.includes(".")) {
            let tmp_price = price_string.split('.');
            // let tmp_price = substring(0, price_string.indexOf("."));
            decimal = tmp_price[0];
            cents = tmp_price[1];

        } else {
            decimal = price_string;
            cents = '00';
        }
    }

    if (getlength(cents) == 1) {
        cents = cents + '0';
    }

    return (part == 'cents') ? cents : transformNumber(parseInt(decimal));
}

/*
*   transformNumber(nb) - Used to split units part from getPartPrice function to add spaces between thousands and millions
*   nb  => number
**/
function transformNumber(nb) {
    const regex = /^[1-9]\d*(,\d{3})*(\.\d+)?$/;
    if (regex.test(nb)) {
        // Transform number to have spaces between millions and thousands
        const transformedNb = nb.toString().replace(/\B(?=(\d{3})+(?!\d)(?!(\.\d+)))/g, " ");
        return transformedNb;
    } else {
        // Return basic number since it needs no transformation
        return nb;
    }
}

/*
*   calculateRating(rating) - Used to return string containing html for rating stars
*   rating => string
**/
function calculateRating(rating) {
    let rating_arr = rating.split('.'),
    rating1 = Number(rating_arr[0]),
    rating2 = Number(rating_arr[1]);
    let rateHasFloat = false;

    let rating_template = ``;


    if (rating > 0) {
        rating_template += `<div class="item_ratings"><span class="netreviews_reviews_rate" data-rate="4.666" data-percent="${(rating / 5) * 100}">`;

        /* As it is done in vertical_product.php */
        for(let i = 1; i <= Math.round(rating); i++) {
            rating_template += '★';
        }
        for(let i = 1; i <= (5 - Math.round(rating)); i++) {
            rating_template += '☆';
        }

        rating_template += `</span> `;

        if (algolia_mobile) {
            rating_template += `${Math.round(rating, 2)}/5</div>`;
        } else {
            rating_template += `<span class="netreviews_reviews_rate">${Math.round(rating, 2)}/5</span></div>`;
        }
    } else {
        return '';
    }

    return rating_template;
}


/*
*   itemTemplate(rating) - Used to return string containing html for rating wshop items
*   rating => string
*   isNoResult => boolean
**/
function itemTemplateMustache(rating, isNoResult = false) {
    return `
    <div id="itm-{{product_id}}" class="item" data-id="itm-{{product_id}}"
    data-insights-object-id="{{objectID}}"
    data-insights-position="{{__position}}"
    data-insights-query-id="{{__queryID}}"
    >
        <div id="addToWishlistButtonContainer">
            <a href="javascript:;" id="addToWishlistButton" data-productid="{{product_id}}"
            data-idelt="{{product_id}}"
            class="btnWishlist bgSprite bfr wishlist"
            onclick="addToWishlist.call(this, '${is_user_connected}');"></a>
        </div>
        <div class="productSlider w-slider-container">
            <div class="algo-wrapper w-slider-wrapper ${(!all_visual_rayon && !algolia_mobile) ? 'rollover' : ''}">
                ${(all_visual_rayon || algolia_mobile) ? `{{#images}}
                    <div class ="w-slider-slide">
                        <a class="block_lnk" href="{{url}}&queryID={{__queryID}}">
                            <img src="{{.}}" class="imgProd" alt="{{name}}"/>
                        </a>
                    </div>
                    {{/images}}` : ''}
                ${(!algolia_mobile) ? `
                            <a class="block_lnk" href="{{url}}&queryID={{__queryID}}">
                            {{#images}}
                                <img src="{{.}}" class="imgProd" alt="{{name}}"/>
                            {{/images}}
                            </a>
                            ` : ''}
            </div>
            ${(all_visual_rayon || algolia_mobile) ? `<div class="btn next" onclick="scrollToNextPage('itm-{{product_id}}')"></div><div class="btn prev" onclick="scrollToPrevPage('itm-{{product_id}}')"></div>` : ''}

            {{#eclat_rond}}
                <div class="eclat_rond"><img src="{{eclat_rond}}" alt=" " /></div>
            {{/eclat_rond}}
            {{#eclat_rect}}
                <div class="eclat_rect"><img src="{{eclat_rect}}" alt=" " /></div>
            {{/eclat_rect}}
        </div>

        <a href="{{url}}" class="wrapper_description">
            <div class="hit-name item_title">
                <h3 class="item_title">{{title}}</h3>
                <span class="item_subtitle">{{subtitle}}</span>
            </div>

            ${!isNoResult ? `
            {{#produit_lot}}
                <div class="item_price">
                    {{#discounted_price_per_produit}}
                        <div class="hit-price pricetag is_discounted is_lot">{{discounted_price_per_produit_unit}}<span class="cent{{no_cents_class_discounted_per_produit}}">,{{discounted_price_per_produit_cent}}</span> <span class="currency">${currencySymbol}</span></div>
                        <div class="hit-price pricetag crossed is_lot">{{price_per_produit_unit}}<span class="cents {{no_cents_class_per_produit}}">,{{price_per_produit_cent}}</span> <span class="currency">${currencySymbol}</span></div>
                    {{/discounted_price_per_produit}}
                    {{^discounted_price_per_produit}}
                        <div class="hit-price pricetag is_lot">{{price_per_produit_unit}}<span class="cents {{no_cents_class_per_produit}}">,{{price_per_produit_cent}}</span> <span class="currency">${currencySymbol}</span></div>
                    {{/discounted_price_per_produit}}
                    <div class="by_piece">${Translator.translate('par_piece')}</div>
                </div>
            {{/produit_lot}}
            {{^produit_lot}}
                <div class="item_price">
                    {{#discounted_price}}
                        <div class="hit-price pricetag is_discounted">{{discounted_price_unit}}<span class="cents {{no_cents_class_discounted}}">,{{discounted_price_cent}}</span> <span class="currency">${currencySymbol}</span></div>
                        <div class="hit-price pricetag crossed">{{price_unit}}<span class="cents {{no_cents_class}}">,{{price_cent}}</span> <span class="currency">${currencySymbol}</span></div>
                    {{/discounted_price}}
                    {{^discounted_price}}
                        <div class="hit-price pricetag">{{price_unit}}<span class="cents {{no_cents_class}}">,{{price_cent}}</span> <span class="currency">${currencySymbol}</span></div>
                    {{/discounted_price}}
                </div>
            {{/produit_lot}}` : ``}

            ${calculateRating(rating)}
        </a>
        ${!isNoResult ? `
        <div class="wrap_rolloverproduit">
            <form id="prod_info_{{product_id}}" onsubmit="return false">
                <input id="produit_id_{{product_id}}" type="hidden" name="produit_id" value="{{product_id}}">
                <input id="suff_{{product_id}}" type="hidden" name="suff" value="{{product_id}}">
                <input id="produit_ref_{{product_id}}" type="hidden" name="produit_ref" value="{{produit_ref}}">
                <input id="produit_refext_{{product_id}}" type="hidden" name="produit_refext" value="{{external_reference}}">
                <input id="produit_principal_{{product_id}}" type="hidden" name="produit_principal" value="{{product_id}}">
                <input id="produit_lot_nb_{{product_id}}" type="hidden" name="produit_lot_nb" value="{{produit_lot_nb}}">
                <input id="titreObjet_{{product_id}}" type="hidden" name="titreObjet" value="{{title}}">
                <input id="idTaille_{{product_id}}" type="hidden" name="idTaille" value="tailleProd_{{size_id}}">
                <input id="idCouleur_{{product_id}}" type="hidden" name="idCouleur" value="couleurProd_{{color_id}}">
                <input id="path_web_{{product_id}}" type="hidden" name="path_web" value="${path_relative_root}">
                {{#produit_lot}}
                    {{#discounted_price_per_produit}}
                        <input id="prixU_{{product_id}}" type="hidden" name="prixU" value="{{discounted_price_per_produit}}">
                    {{/discounted_price_per_produit}}
                    {{^discounted_price_per_produit}}
                        <input id="prixU_{{product_id}}" type="hidden" name="prixU" value="{{price_per_produit}}">
                    {{/discounted_price_per_produit}}
                {{/produit_lot}}
                {{^produit_lot}}
                    {{#discounted_price}}
                        <input id="prixU_{{product_id}}" type="hidden" name="prixU" value="{{discounted_price}}">
                    {{/discounted_price}}
                    {{^discounted_price}}
                        <input id="prixU_{{product_id}}" type="hidden" name="prixU" value="{{price}}">
                    {{/discounted_price}}
                {{/produit_lot}}

                <div class="rollover_left achat_express">
                    <div id="ligne_couleur_{{product_id}}" class="ligne_form productColorFieldset">
                        <div class="prod_listes right_element_prod">
                            <div class="titre_choices_list">${Translator.translate('choose_color')}</div>
                            <div class="choices_list">
                                <a href={{url}} data-color="{{color}}" class="form_itm check color">
                                    <input type="hidden" name="itm_id_{{color_id}}" value="{{product_id}}">
                                    <input id="color_{{product_id}}_{{color_id}}" type="radio" name="itm_color" value="{{color_id}}" data-pic="" data-nom="{{color}}" disabled="disabled" class="input_check radio prodColor color_{{product_id}} color" checked>
                                    <span data-hex="{{color_code_hex}}" for="color_{{product_id}}_{{color_id}}" title="{{color}}></span>
                                </a>
                                <input id="couleurProd_{{product_id}}" type="hidden" name="couleurProd" value="{{color_id}}" data-produitid="{{product_id}}">
                            </div>
                        </div>
                    </div>
                    <div id="ligne_pointure_{{product_id}}" class="ligne_form productSizeFieldset fromExpressV2">
                        <div id="size_list_{{product_id}}" class="prod_listes size_list right_element_prod">
                            <div class="titre_choices_list">${Translator.translate('choose_size')}</div>
                            <div class="choices_list">
                                <div class="form_itm check size">
                                    <input id="size_{{product_id}}_{{size_id}}" type="hidden" name="itm_size" value="{{size_id}}" data-pic="" data-nom="{{size}}" class="input_check radio prodSize size">
                                    <label for="size_{{product_id}}_{{size_id}}" title="{{size}}">{{size}}</label>
                                </div>
                            </div>
                        </div>
                        <input id="tailleProd_{{product_id}}" type="hidden" name="tailleProd" value="{{size_id}}" data-produitid="{{product_id}}">
                    </div>
                    <input id="qteProd_{{product_id}}" type="hidden" name="qteProd" value="1">
                </div>

                <div id="bloc_add_alert_{{product_id}}"
                    class="full btnAddBasketWrapper expressAlertStock bloc_add_alert_{{product_id}}"
                    style="display:none">
                    <div class="alert_stock">
                        <span>${Translator.translate('receive_alert')}</span>
                    </div>

                    <div class="bloc_add_alert_form">
                        <div class="w-form-line">
                            <div class="w-input-container">
                                <label class="w-input w-text-input">
                                    <input id="mail_alerte_stock_{{product_id}}"
                                        class="w-input-element" type="text" name="mail_alerte_stock"
                                        value="${user_mail}"
                                        placeholder="${Translator.translate('alert_stock_placeholder')}"/>
                                </label>
                            </div>
                            <div class="form_submit">
                                <button id="send_mail_alert_stock_{{product_id}}" type="button"
                                        onclick="sendMailAlertStock('{{product_id}}', this.form, '')">
                                    <span>${Translator.translate('send_alert_stock')}</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="bloc_add_alert_confirmation" style="display:none">
                        <p>${Translator.translate('txt_confirmation_alerte_mail')}</p>
                    </div>

                    <div class="bloc_add_alert_erreur" style="display:none">
                        <p>${Translator.translate('txt_erreur_alerte_mail')}</p>

                        <button class="w-submit-button" onclick="lightboxAlertStock(this.form)">
                            <span>${Translator.translate('close')}</span>
                        </button>
                    </div>

                    <a class="alert_return"
                    onclick="closeAlerteStock('{{product_id}}')">
                        <span>X</span>
                    </a>
                </div>

                <div class="form_submit bloc_add_color wrapper_add_color_{{product_id}}">
                    <button id="bloc_add_color_{{product_id}}" type="button"
                            class="button dark w-submit-button" data-is-preco="<?php echo $is_preorder; ?>"
                            onclick="achatExpressV2.call(this, '{{product_id}}', '');">
                        <span>${Translator.translate('achat_express_v2')}</span>
                    </button>

                    <button class="button loader">
                        <span>${Translator.translate('loading')}</span>
                    </button>
                </div>
            </form>
        </div>` : ``}
    </div>`;
}
function itemTemplate(hit, rating, isNoResult = false) {

    // Use loops before retuning the string for array elements
    let images_template = ``;
    if (hit.images.length) {
        // Start images string by its container
        if (algolia_mobile) {
            if (!isNoResult) {
                images_template +=  `<div class="searchItemSlider swiper-container wrapper_img" onClick="StoreScrollPosition()"><div class="swiper-wrapper">`;
            }
        }

        // Loop to add all images inside the container
        for (let i = 0; i < hit.images.length; i++) {
            if (algolia_mobile) {
                // Mobile template
                if (!isNoResult) {
                    // Swipers if classic search results
                    images_template +=
                    `<div class="swiper-slide">
                        <img src="${hit.images[i]}" class="swiper-no-lazy" alt="${escapeHtml(hit.title)}"/>
                    </div>` ;
                }
            } else {
                /* images_template +=
                `<img src="${hit.images[i]}" class="itm_vis imgProd ${(i > 0) ? 'rollover_picture' : ''}" alt="${hit.title}"/>` ; */

                images_template +=
                `<span data-src="${hit.images[i]}" class="ill_img itm_vis imgProd ${(i > 0) ? 'rollover_picture' : ''}" alt="${escapeHtml(hit.title)}"></span>` ;
            }
        }

        // Exception for mobile no results page
        if (algolia_mobile) {
            if (isNoResult) {
                images_template += `<div class="photo_product">
                    <div class="wrapper_img">
                        <img src="${hit["image-url"]}" alt="${hit.title}" />
                        ${hit.eclat_stock ?
                            `<div class="eclat_product"><img src="${path_relative_root + 'img/eclat/' + lang + '/' + hit.eclat_stock}" alt="" /></div>`
                            :
                            `${hit.eclat_rond ?
                                `<div class="eclat_product"><img src="${path_relative_root + hit.eclat_rond}" alt="" /></div>`
                                :
                                ''
                            }`
                        }
                    </div>`
            }
        }

        // Close container and add eclats logic
        if (algolia_mobile) {
            if (!isNoResult) {
                images_template +=
                `</div></div>${hit.eclat_stock ?
                    `<div class="eclat_product"><img src="${path_relative_root + 'img/eclat/' + lang + '/' + hit.eclat_stock}" alt="" /></div>`
                    :
                    `${hit.eclat_rond ?
                        `<div class="eclat_product"><img src="${path_relative_root + hit.eclat_rond}" alt="" /></div>`
                        :
                        ''
                    }`
                }`;
            }
        }
    }

    if (algolia_mobile) {
        return `
        <div id="i${hit.product_id}" class="item list_item item_container" data-prod="${hit.product_id}"
        data-insights-object-id="${hit.objectID}"
        data-insights-position="${hit.__position}"
        data-insights-query-id="${hit.__queryID}"
        >
            <a href="javascript:;" id="addToWishlistButton_${hit.product_id}" data-productid="${hit.product_id}"
            data-idelt="${hit.product_id}"
            class="wishlistBtn addToWishlistButton"
            onclick="addToWishlistRay.call(this, '${is_user_connected}');return false;"></a>
            <a href="${hit.url}&queryID=${hit.__queryID}" data-ec-type="product" data-ref="${hit.produit_ref}" class="item_link">
                ${images_template}
            </a>
            <div class="item_btm">
                ${hit.eclat_rect ?
                    `<img class="eclat_rect" src="${path_relative_root + hit.eclat_rect}" alt="" />`
                    :
                    ''
                }
                <div class="wrap_txt">
                    <div class="title">
                        <span class="product_title">${hit.title}</span>
                        <span class="subTitleRayon">${hit.subtitle}</span>
                    </div>
                </div>
                ${hit.produit_lot ?
                    `<div class="wrap_price">
                        ${hit.discounted_price_per_produit ?
                            `<p class="price_tag new_price">${hit.discounted_price_per_produit_unit}<sup class="currency">${currencySymbol}</sup><span class="cent number_cent ${hit.no_cents_class_discounted_per_produit}">,${hit.discounted_price_per_produit_cent}</span></p>
                            <p class="price_tag new_price">${hit.price_per_produit_unit}<sup class="currency">${currencySymbol}</sup><span class="cents number_cent ${hit.no_cents_class_per_produit}">,${hit.price_per_produit_cent}</span></p>`
                            :
                            `
                            <p class="hit-price price_tag is_lot">${hit.price_per_produit_unit}<sup class="currency">${currencySymbol}</sup><span class="cents number_cent ${hit.no_cents_class_per_produit}">,${hit.price_per_produit_cent}</span></p>`
                        }
                        <div class="by_piece">${Translator.translate('par_piece')}</div>
                    </div>`
                    :
                    `<div class="wrap_price">
                        ${hit.discounted_price ?
                            `
                            <p class="price_tag new_price">${hit.discounted_price_unit}<sup class="currency">${currencySymbol}</sup><span class="cents number_cent ${hit.no_cents_class_discounted}">,${hit.discounted_price_cent}</span></p>
                            <p class="price_tag new_price">${hit.price_unit}<sup class="currency">${currencySymbol}</sup><span class="cents number_cent ${hit.no_cents_class}">,${hit.price_cent}</span></p>`
                            :
                            `
                            <p class="hit-price price_tag is_lot">${hit.price_unit}<sup class="currency">${currencySymbol}</sup><span class="cents number_cent ${hit.no_cents_class}">,${hit.price_cent}</span></p>`
                        }
                    </div>`
                }
                ${calculateRating(rating)}
                ${itemAssociationsTemplateMobile(hit)}
            </div>
        </div>`;
    } else {
        return `
        <div id="itm-${hit.product_id}" class="item vitem" data-id="itm-${hit.product_id}"
        data-insights-object-id="${hit.objectID}"
        data-insights-position="${hit.__position}"
        data-insights-query-id="${hit.__queryID}"
        >
            <div id="addToWishlistButtonContainer">
                <a href="javascript:;" id="addToWishlistButton" data-productid="${hit.product_id}"
                data-idelt="${hit.product_id}"
                class="btnWishlist bgSprite bfr wishlist"
                onclick="addToWishlist.call(this, '${is_user_connected}');"></a>
            </div>

            <a href="${hit.url}&queryID=${hit.__queryID}" class="block_lnk" onClick="StoreScrollPosition()">
                <div class="rolloverimgContainer ${(!all_visual_rayon && !algolia_mobile) ? 'rollover' : ''}">
                    <div class="wrapper_eclat">
                        ${hit.eclat_stock ?
                            `
                            <div class="stock_depleted mark">
                                <img class="eclat_stock" src="${path_relative_root + 'img/eclat/' + lang + '/' + hit.eclat_stock}" alt=" " />
                            </div>
                            ${hit.eclat_rect ?
                                `<div class="eclat_horiz"><img src="${path_relative_root + hit.eclat_rect}" alt="" /></div>`
                                :
                                ''
                            }
                            `
                            :
                            `${hit.eclat_rond ?
                                `<div class="see_on_tv mark"><img src="${path_relative_root + hit.eclat_rond}" alt="" /></div>`
                                :
                                ''
                            }
                            ${hit.eclat_rect ?
                                `<div class="eclat_horiz"><img src="${path_relative_root + hit.eclat_rect}" alt="" /></div>`
                                :
                                ''
                            }`
                        }
                    </div>
                    ${images_template}
                    ${itemColorsTemplate(hit)}

                </div>
                <div class="wrapper_description">
                    <div class="hit-name name">
                        <h2 class="item_title item_name">${escapeHtml(hit.title)}</h2>
                        <p class="item_subtitle item_feat" title="${escapeHtml(hit.subtitle)}">${escapeHtml(hit.subtitle)}</p>
                    </div>

                    ${!isNoResult ? `
                        ${hit.produit_lot ?
                            `<div class="price">
                                ${hit.discounted_price_per_produit ?
                                    `<div class="hit-price pricetag new-price is_lot">${hit.discounted_price_per_produit_unit}<sup class="currency">${currencySymbol}</sup><span class="cent number_cent ${hit.no_cents_class_discounted_per_produit}">,${hit.discounted_price_per_produit_cent}</span></div>
                                    <div class="hit-price pricetag is_lot crossed">${hit.price_per_produit_unit}<sup class="currency">${currencySymbol}</sup><span class="cents number_cent ${hit.no_cents_class_per_produit}">,${hit.price_per_produit_cent}</span></div>`
                                    :
                                    `
                                    <div class="hit-price pricetag is_lot">${hit.price_per_produit_unit}<sup class="currency">${currencySymbol}</sup><span class="cents number_cent ${hit.no_cents_class_per_produit}">,${hit.price_per_produit_cent}</span></div>`
                                }
                                <div class="by_piece">${Translator.translate('par_piece')}</div>
                            </div>`
                            :
                            `<div class="price">
                                ${hit.discounted_price ?
                                    `<div class="hit-price pricetag is_lot new-price">${hit.discounted_price_unit}<sup class="currency">${currencySymbol}</sup><span class="cents number_cent ${hit.no_cents_class_discounted}">,${hit.discounted_price_cent}</span></div>
                                    <div class="hit-price pricetag is_lot crossed">${hit.price_unit}<sup class="currency">${currencySymbol}</sup><span class="cents number_cent ${hit.no_cents_class}">,${hit.price_cent}</span></div>`
                                    :
                                    `
                                    <div class="hit-price pricetag is_lot">${hit.price_unit}<sup class="currency">${currencySymbol}</sup><span class="cents number_cent ${hit.no_cents_class}">,${hit.price_cent}</span></div>`
                                }
                            </div>`
                        }`
                        :
                        ``
                    }
                </div>
                ${calculateRating(rating)}
            </a>

            ${!isNoResult ? `
            <div class="wrap_rolloverproduit">
                <div class="wrap_rolloverproduit_container">
                    <form id="prod_info_${hit.product_id}" onsubmit="return false">
                        <input id="produit_id_${hit.product_id}" type="hidden" name="produit_id" value="${hit.product_id}">
                        <input id="suff_${hit.product_id}" type="hidden" name="suff" value="${hit.product_id}">
                        <input id="produit_ref_${hit.product_id}" type="hidden" name="produit_ref" value="${hit.produit_ref}">
                        <input id="produit_refext_${hit.product_id}" type="hidden" name="produit_refext" value="${hit.external_reference}">
                        <input id="produit_principal_${hit.product_id}" type="hidden" name="produit_principal" value="${hit.product_id}">
                        <input id="produit_lot_nb_${hit.product_id}" type="hidden" name="produit_lot_nb" value="${hit.produit_lot_nb}">
                        <input id="titreObjet_${hit.product_id}" type="hidden" name="titreObjet" value="${hit.title}">
                        <input id="idTaille_${hit.product_id}" type="hidden" name="idTaille" value="tailleProd_${hit.size_id}">
                        <input id="idCouleur_${hit.product_id}" type="hidden" name="idCouleur" value="couleurProd_${hit.color_id}">
                        <input id="path_web_${hit.product_id}" type="hidden" name="path_web" value="${path_relative_root}">
                        ${hit.produit_lot ?
                            `${hit.discounted_price_per_produit ?
                                `<input id="prixU_${hit.product_id}" type="hidden" name="prixU" value="${hit.discounted_price_per_produit}">`
                                :
                                `<input id="prixU_${hit.product_id}" type="hidden" name="prixU" value="${price_per_produit}">`
                            }`
                            :
                            `${hit.discounted_price ?
                                `<input id="prixU_${hit.product_id}" type="hidden" name="prixU" value="${hit.discounted_price}">`
                                :
                                `<input id="prixU_${hit.product_id}" type="hidden" name="prixU" value="${hit.price}">`
                            }`
                        }

                        <div class="rollover_left achat_express">
                            <div id="addToWishlistButtonContainer">
                                <a href="javascript:;" id="addToWishlistButton_${hit.product_id}" data-productid="${hit.product_id}"
                                data-product-ref="${hit.produit_ref}" data-idelt="${hit.product_id}"
                                class="wishlistBtn addToWishlistButton"
                                onclick="addToWishlistRay.call(this, '${is_user_connected}');return false;"></a>
                            </div>
                            <div id="ligne_couleur_${hit.product_id}" class="ligne_form productColorFieldset cache">
                                <div class="prod_listes right_element_prod">
                                    <div class="titre_choices_list">${Translator.translate('choose_color')}</div>
                                    <div class="choices_list">
                                        ${itemAssociationsTemplate(hit)}
                                        <input id="couleurProd_${hit.product_id}" type="hidden" name="couleurProd" value="${hit.color_id}" data-produitid="${hit.product_id}">
                                    </div>
                                </div>
                            </div>
                            <div id="ligne_pointure_${hit.product_id}" class="ligne_form productSizeFieldset fromExpressV2 cache">
                                <div id="size_list_${hit.product_id}" class="prod_listes size_list right_element_prod">
                                    <div class="titre_choices_list">${Translator.translate('choose_size')}</div>
                                    <div class="choices_list">
                                        <div class="form_itm check size">
                                            <input id="size_${hit.product_id}_${hit.size_id}" type="hidden" name="itm_size" value="${hit.size_id}" data-pic="" data-nom="${hit.size}" class="input_check radio prodSize size">
                                            <label for="size_${hit.product_id}_${hit.size_id}" title="${hit.size}">${hit.size}</label>
                                        </div>
                                    </div>
                                </div>
                                <input id="tailleProd_${hit.product_id}" type="hidden" name="tailleProd" value="${hit.size_id}" data-produitid="${hit.product_id}">
                            </div>
                            <input id="qteProd_${hit.product_id}" type="hidden" name="qteProd" value="1">
                        </div>

                        <div id="bloc_add_alert_${hit.product_id} cache"
                            class="full btnAddBasketWrapper expressAlertStock bloc_add_alert_${hit.product_id}"
                            style="display:none">
                            <div class="alert_stock">
                                <span>${Translator.translate('receive_alert')}</span>
                            </div>

                            <div class="bloc_add_alert_form">
                                <div class="w-form-line">
                                    <div class="w-input-container">
                                        <label class="w-input w-text-input">
                                            <input id="mail_alerte_stock_${hit.product_id}"
                                                class="w-input-element" type="text" name="mail_alerte_stock"
                                                value="${user_mail}"
                                                placeholder="${Translator.translate('alert_stock_placeholder')}"/>
                                        </label>
                                    </div>
                                    <div class="form_submit">
                                        <button id="send_mail_alert_stock_${hit.product_id}" type="button"
                                                onclick="sendMailAlertStock('${hit.product_id}', this.form, '')">
                                            <span>${Translator.translate('send_alert_stock')}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="bloc_add_alert_confirmation" style="display:none">
                                <p>${Translator.translate('txt_confirmation_alerte_mail')}</p>
                            </div>

                            <div class="bloc_add_alert_erreur" style="display:none">
                                <p>${Translator.translate('txt_erreur_alerte_mail')}</p>

                                <button class="w-submit-button" onclick="lightboxAlertStock(this.form)">
                                    <span>${Translator.translate('close')}</span>
                                </button>
                            </div>

                            <a class="alert_return"
                            onclick="closeAlerteStock('${hit.product_id}')">
                                <span>X</span>
                            </a>
                        </div>

                        ${!hit.eclat_stock ?
                            `<div class="form_submit bloc_add_color wrapper_add_color_${hit.product_id}">
                                <button id="bloc_add_color_${hit.product_id}" type="button"
                                        class="button dark w-submit-button" data-is-preco="<?php echo $is_preorder; ?>"
                                        onclick="achatExpressV2.call(this, '${hit.product_id}', '');">
                                    <span>${Translator.translate('achat_express_v2')}</span>
                                </button>

                                <button class="button loader">
                                    <span>${Translator.translate('loading')}</span>
                                </button>
                            </div>`
                            :
                            ``
                        }

                        ${(hit.eclat_stock && !hit.eclat_stock.includes('epuise')) ?
                            `<div class="form_submit bloc_add_color no_stock wrapper_add_color_${hit.product_id}"><button id="bloc_add_color_${hit.product_id}" type="button" data-is-preco="" onclick="algoliaAlertStock($(this), '${hit.product_id}', '');" class="button dark w-submit-button out_stock"><span>${Translator.translate('receive_alert')}</span></button> <button class="button loader"><span>loading</span></button></div>`
                            :
                            ''
                        }
                    </form>

                </div>
            </div>` : ``}
        </div>`;
    }
}

/**
 * Init algolia search
 */
function initAlgolia() {

    var algoliaSearchTop = document.querySelector('.algoliaSearchTop');
    var algoliaSearch = document.querySelector('.ais-container');
    var algoliaSearchInput = document.querySelector('.ais-SearchBox input');
    var algoliaResults = document.querySelector('.ais-InstantSearch');
    algoliaSearch.classList.add('inUse');
    algoliaSearchInput.focus();

}

/**
 * Reset Algolia search
 */
function resetAlgolia() {
    var algoliaSearchInput = $('.aa-Input');
    setTimeout(function() {
        document.querySelector('.ais-container').classList.remove('inUse');
        document.querySelector('.ais-InstantSearch').classList.remove('displayed');
        algoliaSearchInput.focusout();
        triggerAlgoInputShad('remove');

    }, 200);
}

/**
 * function initializeSwiper
 *
 *
 */
function initializeSwiper(container, params) {
    new Swiper(container, params);
}



/**
 * function initializeSwiper
 *
 * Used to init product sliders in category / search page
 */
function initItemSlider(isToggled) {
    if ($('#hits .prodItemSlider, #hits .searchItemSlider').length) {
        if (isToggled == true) { // Toggle view is triggerred
            setTimeout(function () {
                $(' #hits .prodItemSlider, .searchItemSlider').each(function () {
                    if ($(this).hasClass('initialized')) {
                        this.swiper.update();
                        this.swiper.slideTo(1);
                    }
                });
            }, 200); // 300 equals the css transition timing defined on .item_container
        } else { // From page load and scroll
            $('.prodItemSlider').each(function () { // Prevent initialized sliders to go back to the first frame during scroll
                if (isInViewport($(this)) && !$(this).hasClass('initialized')) {
                    new Swiper($(this), {
                        roundLengths: true,
                        slidesPerView: 1,
                        loop: true,
                        preloadImages: false,
                        observer: true,
                        observeParents: true,
                        pagination: '.itemSliderPagination',
                    });
                    $(this).addClass('initialized');
                }
            });

            $(".searchItemSlider").each(function () {
                if (isInViewport($(this).parents('.ais-InfiniteHits-item')[0]) && !$(this).hasClass('initialized')) {
                    new Swiper($(this), {
                        roundLengths: true,
                        slidesPerView: 1,
                        loop: true,
                        preloadImages: false,
                        observer: true,
                        observeParents: true,
                        pagination: '.itemSliderPagination',
                    });
                    $(this).addClass('initialized');
                }
            });
        }
    }
}

/**
 * function initializeSuggSwiper
 *
 *
 */
function initializeSuggSwiper(container, params) {
    new Swiper(container, params);
}

function itemAssociationsTemplate(hit) {
    if (hit !== 'undefined') {
        let colors = hit.associated_products;
        let string_associations = ``;

        if (colors.length) {
            // Product has more than one color
            if (colors.length > 1) {
                for (let i = 0; i < (((max_color_display !== undefined) && (colors.length > max_color_display)) ? (max_color_display - 1) : colors.length) ; i++) {
                    if (i == 0 && colors[i].main_image_url.length) {
                        string_associations +=
                        `<a href="${hit.url}" data-color="${hit.color}" class="form_itm check color">
                            <input type="hidden" name="itm_id_${hit.product_id}" value="${hit.product_id}">
                            <input id="color_${hit.product_id}_${hit.color_id}" type="radio" name="itm_color" value="${hit.color_id}" data-pic="" data-nom="${hit.color}" disabled="disabled" class="input_check radio prodColor color_${hit.product_id} color" checked="checked">
                            <span data-hex="${hit.color_code}" for="color_${hit.product_id}_${hit.color_id}" title="${hit.color}" style="background-image: url(${colors[i].main_image_url});"></span>
                        </a>`
                    }
                    string_associations +=
                    `<a href="${colors[i].url}" data-color="${colors[i].colors.couleur_nom}" class="form_itm check color">
                        <input type="hidden" name="itm_id_${colors[i].id}" value="${hit.product_id}">
                        <input id="color_${hit.product_id}_${colors[i].id}" type="radio" name="itm_color" value="${colors[i].id}" data-pic="" data-nom="${colors[i].colors.couleur_nom}" disabled="disabled" class="input_check radio prodColor color_${hit.product_id} color">
                        <span data-hex="${colors[i].colors.couleur_code_hex}" for="color_${hit.product_id}_${colors[i].id}" title="${colors[i].colors.couleur_nom}" style="background-image: url(${colors[i].image_url});"></span>
                    </a>`;
                }

                // If there are still colors existing but not displayed
                if ((colors.length - max_color_display) > 0) {
                    string_associations +=
                    `<a href="${hit.url}" class="remaining_colors">+ ${colors.length - max_color_display}</a>`;
                }
            } else {
                string_associations +=
                `<a href="${hit.url}" data-color="${hit.color}" class="form_itm check color">
                    <input type="hidden" name="itm_id_${hit.product_id}" value="${hit.product_id}">
                    <input id="color_${hit.product_id}_${hit.color_id}" type="radio" name="itm_color" value="${hit.color_id}" data-pic="" data-nom="${hit.color}" disabled="disabled" class="input_check radio prodColor color_${hit.product_id} color" checked="checked">
                    <span data-hex="${hit.color_code}" for="color_${hit.product_id}_${hit.color_id}" title="${hit.color}" style="background-image: url(${colors[0].main_image_url});"></span>
                </a>
                <a href="${colors[0].url}" data-color="${colors[0].colors.couleur_nom}" class="form_itm check color">
                    <input type="hidden" name="itm_id_${colors[0].id}" value="${hit.product_id}">
                    <input id="color_${hit.product_id}_${colors[0].id}" type="radio" name="itm_color" value="${colors[0].id}" data-pic="" data-nom="${colors[0].colors.couleur_nom}" disabled="disabled" class="input_check radio prodColor color_${hit.product_id} color">
                    <span data-hex="${colors[0].colors.couleur_code_hex}" for="color_${hit.product_id}_${colors[0].id}" title="${colors[0].colors.couleur_nom}" style="background-image: url(${colors[0].image_url});"></span>
                </a>`;
            }
        } else {
            string_associations +=
                `<a href="${hit.url}" data-color="${hit.color}" class="form_itm check color cache">
                    <input type="hidden" name="itm_id_${hit.product_id}" value="${hit.product_id}">
                    <input id="color_${hit.product_id}_${hit.color_id}" type="radio" name="itm_color" value="${hit.color_id}" data-pic="" data-nom="${hit.color} disabled="disabled" class="input_check radio prodColor color_${hit.product_id} color">
                    <span data-hex="${hit.color_code}" for="color_${hit.product_id}_${hit.color_id}" title="${hit.color}"></span>
                </a>`;
        }

        return string_associations;
    } else {
        return;
    }
}
function itemColorsTemplate(hit) {
    if (hit !== 'undefined') {
        let colors = hit.associated_products;
        let string_associations = ``;
        if ((typeof colors == 'object') && colors.length) {
            // Product has more than one color
            string_associations += `<div class="couleur_produit">`;
            if (colors.length > 1) {
                // Add first main product color before its associations
                // add both image and variant color as backgrounds so that the variant color can be used as a fallback if the image is not available

                let pathPhotoColor = hit['image-url'].replace(hit.product_id + 'A', hit.product_id + 'Z');
                let pathColor = path_relative_root + 'img/couleur/' + hit.color_id + '.jpg';

                string_associations += `<span data-color="${hit.color}" title="${hit.color}" class="img_couleur_horiz picto_color" style="background-image: url(${pathPhotoColor}), url(${pathColor});"></span>`;

                for (let i = 0; i < (((max_color_display !== undefined) && (colors.length > max_color_display)) ? (max_color_display - 1) : colors.length) ; i++) {

                    let pathPhotoColor = colors[i].image_url.replace('A.jpg', 'Z.jpg');
                    string_associations +=
                    `<span data-color="${colors[i].colors.couleur_nom}" title="${colors[i].colors.couleur_nom}" class="img_couleur_horiz picto_color" style="background-image: url(${pathPhotoColor});"></span>`;
                }

                // If there are still colors existing but not displayed
                if ((colors.length - max_color_display) > 0) {
                    string_associations +=
                    `<span class="remaining_colors nb_products_left">+ ${colors.length - max_color_display}</span>`;
                }
            } else {

                let pathPhotoColor = colors[0].image_url.replace(colors[0].id + 'A', colors[0].id + 'Z');
                let pathColor = path_relative_root + 'img/couleur/' + colors[0].colors.couleur_id + '.jpg';

                string_associations +=
                `<span data-color="${colors[0].colors.couleur_nom}" title="${hit.color}" class="img_couleur_horiz picto_color" style="background-image: url(${pathPhotoColor}), url(${pathColor});"></span>`;
            }
            string_associations += `</div>`;
        }

        return string_associations;
    } else {
        return;
    }
}

function itemAssociationsTemplateMobile(hit) {
    let colors = hit.associated_products;
    let string_associations = ``;

    if (colors.length) {
        string_associations += `<div class="item_available_colors choices_list">`
        // Product has more than one color
        if (colors.length > 1) {
            for (let i = 0; i < (((max_color_display !== undefined) && (colors.length > max_color_display)) ? (max_color_display - 1) : colors.length) ; i++) {

                if (i == 0 && colors[i].main_image_url.length) {
                    let pathMainPhotoColor = colors[i].main_image_url.replace(hit.product_id + 'A', hit.product_id + 'Z');

                    string_associations +=
                    `<a href="${hit.url}" class="item_color_bloc actif" style="background-image: url(${pathMainPhotoColor});">
                    </a>`;
                }

                let pathPhotoColor = colors[i].image_url.replace('A.jpg', 'Z.jpg');

                string_associations +=
                `<a href="${colors[i].url}" class="item_color_bloc" style="background-image: url(${pathPhotoColor});"></a>`;
            }

            string_associations += `<input id="couleurProd_${hit.product_id}_${colors[0].id}" type="hidden" name="couleurProd" value="${colors[0].id}" />`

            // If there are still colors existing but not displayed
            if ((colors.length - max_color_display) > 0) {
                string_associations +=
                `<a href="${hit.url}" class="remaining_colors">+${colors.length - max_color_display}</a>`;
            }
        } else {

            let pathMainPhotoColor = colors[0].main_image_url.replace(hit.product_id + 'A', hit.product_id + 'Z');
            let pathPhotoColor = colors[0].image_url.replace('A.jpg', 'Z.jpg');

            string_associations +=
            `<a href="${hit.url}" class="item_color_bloc actif" style="background-image: url(${pathMainPhotoColor});"></a>
            <a href="${colors[0].url}" class="item_color_bloc" style="background-image: url(${pathPhotoColor});"></a>
            <input id="couleurProd_${hit.product_id}_${colors[0].id}" type="hidden" name="couleurProd" value="${colors[0].id}" />`;
        }
        string_associations += `</div>`
    } else {
        string_associations +=
            `<input id="couleurProd_${hit.product_id}_${hit.color_id}" type="hidden" name="couleurProd" value="${hit.color_id}" />`;
    }
    return string_associations;
}


/** function decode : used to parse html special chars
 * str = string;
 *
 */
function decode(str) {
    let txt = new DOMParser().parseFromString(str, "text/html");
    return txt.documentElement.textContent;
}

/** function decode : used to parse html special chars
 * str = string;
 *
 */
function checkToggledFilter(filter) {
    let html_class = "";
    if (filter && (filter !== undefined)) {
        if ($(`#filter_sticky_search #${filter}`).parents('.filters_dropdown_bloc').hasClass('body_visible')) {
            html_class = "toggled";
        }
    }

    return html_class;
}

// Custom alert stock stock --> achatExpressV2 override only for Algolia
function algoliaAlertStock(currentElem, product_id, regroup_ref_id) {
    let elem = regroup_ref_id ? '_' + product_id + regroup_ref_id : '_' + product_id;

    currentElem.parent().hide();
    $('#bloc_add_alert' + elem).show();
}


/* function escapeHTML --> htmlspecialchars() equivalent */
function escapeHtml(text) {
    var map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#039;'
    };

    return text.replace(/[&<>"']/g, function(m) { return map[m]; });
}

function getlength(number) {
    return number.toString().length;
}

/** function StoreScrollPosition :
 * Store in local storage scroll position when user goes to product Pageview
 * Position  will used on return to algolia search page
 */
function StoreScrollPosition() {
    if (!algolia_mobile) {
        sessionStorage.setItem('scrollPosition', window.pageYOffset);
    } else {
        var el = document.querySelector('.ais-InstantSearch');
        var position = el.scrollTop;
        sessionStorage.setItem('scrollPosition', position);
    }
}
/** function setBackToTop :
 * Sets scroll position back to top of page
 */
function setBackToTop() {
    const target = algolia_mobile ? ".ais-InstantSearch" : "html, body"
    $(target).animate({
        scrollTop: 0
    }, 0);
}

/** function removeHTMLAndScripts :
 * query : type String
 */
function removeHTMLAndScripts(query) {
    // Replaces HTML characters into spaces
    let cleanInput = query.replace(/<[^>]*>/g, '');
    // Removes JavaScript scripts
    cleanInput = cleanInput.replace(/<script[^>]*>[\s\S]*?<\/script>/gi, '');
    // Removes risky codes
    cleanInput = cleanInput.replace(/[{}()]/g, '');
    // Removes special chars usually used on XSS attacks
    cleanInput = cleanInput.replace(/[&<>"'/]/g, function (match) {
        return {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#39;',
            "/": '&#x2F;'
        }[match];
    });
    return cleanInput;
}

/** function isStringCorrupted :
 * string : type String
 * Checks if string contains HTML or script characters
 */
function isStringCorrupted(string) {
    const htmlScriptRegex = /(<([^>]+)>|<script[^>]*>[\s\S]*?<\/script>)/ig;

    return htmlScriptRegex.test(string) ? true : false;
}

function preferredOrder(obj, order) {
    var newObject = {};
    for(var i = 0; i < order.length; i++) {
        if(obj.hasOwnProperty(order[i])) {
            newObject[order[i]] = obj[order[i]];
        }
    }
    return newObject;
}